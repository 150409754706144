.selectPlayerContainer {
  width: 100%;
  margin-top: 13px;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 13px;
}

.countdownCardFix {
  // max-width: 600px;
  width: 100%;
  height: 100%;
  align-items: center;
}

.sponsorBand {
  height: 70px;

  & > button {
    background-color: #EEF0F8 !important;
    border-color: #EEF0F8 !important;
  }
}

.sponsorTitle {
  color: #807777;
  font-size: 17px;
}