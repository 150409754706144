$pagination-button-color: rgb(73,73,73);

.control-container .ots-video-control {
  width: 50px;
  height: 50px;
  margin: 10px 3px 10px 0 !important;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-color: rgba(27, 134, 144, 0.4);
  background-color: lightgrey;
  background-repeat: no-repeat;
  cursor: pointer;
}
.control-container .ots-video-control.audio {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}
.control-container .ots-video-control.audio.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}
.control-container .ots-video-control.video {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}
.control-container .ots-video-control.video.disabled,
.control-container .ots-video-control.audio.disabled {
  pointer-events: none;
  background-color: lightgrey;
  border-color: lightgrey;
  opacity: 0.5;
}
.control-container .ots-video-control.video.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}
.opentok-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .OT_fit-mode-cover,
  .OT_video-poster {
    display: block;
    object-fit: contain !important;
    background-position: center !important;
    background-size: 55%;
  }
  .OT_video-element {
    object-fit: contain !important;
  }
  .OT_mode-auto {
    bottom: 0;
    top: unset;
    opacity: 1;
  }
  .OT_name {
    text-align: center;
  }
  &.once {
    &:not(.screen-sharing) .vonage-meeting {
      height: 100%;
    }
  }
  &.screen-sharing {
    .screen-share-block {
      display: block;
      flex: unset;
      height: 70%;
    }
    .vonage-meeting {
      height: calc(25% - 10px) !important;
      margin-bottom: 10px;
      min-height: 60px;
      overflow-x: auto;
      >div {
        width: 100% !important;
        height: 100% !important;
        min-width: 250px;
      }
    }
  }
  .vonage-meeting {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
    gap: 10px;
    width: 100%;
    overflow-x: auto;
    background-color: black;
    margin: 0 auto;
    position: relative;
    &::-webkit-scrollbar-thumb {
      background: #585858;
    }
    .custom-loader{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;
      background-color: black;
    }
    > div {
        width: 100% !important;
        height: 100% !important;
        min-width: 250px;
        // min-height: 100px;
    }
    .OT_name {
      width: 100%;
    }
    video {
      pointer-events: none !important;
    }
    .OT_edge-bar-item {
      height: 34px;
      line-height: 34px;
      top: auto !important;
      bottom: 0 !important;
    }
  }

  &:not(.screen-sharing) {
    .vonage-meeting {
      width: 100%;
      height: 100%;
    }
    .screen-share-block{
      display: none;
    }
  }
  &.screen-sharing {
    .vonage-meeting {
      overflow: auto;
      flex-wrap: nowrap;
      flex-direction: row;
      display: inline-flex !important;
      .OT_video-poster{
        background-size: 25%;
      }
    }
    > div:not(.control-container) .vonage-meeting {
      .OTPublisherContainer,
      .OT_subscriber {
        height: 100% !important;
      }
    }
    .OT_edge-bar-item {
      line-height: 18px;
      height: 18px;
    }
  }
  > div:not(.control-container) {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: black;
    .vonage-meeting {
      .OTPublisherContainer {
        width: 100% !important;
      }
      .OT_mode-on {
        bottom: 0 !important;
        top: auto;
      }
      .OT_name {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        right: unset;
      }
      &.small {
        .OT_edge-bar-item {
          height: 26px;
          line-height: 26px;
        }
      }
    }
  }
  #screen-preview {
    width: 100% !important;
    height: 100% !important ;
    text-align: center;
    left: 0;
    & video {
      height: 100%;
      object-fit: contain;
    }
  }
  .video-container {
    &.small {
      z-index: 2;
      .OTPublisherContainer,
      .OT_subscriber {
        width: 100% !important;
        height: 24vh !important;
      }
    }
  }

  .screen-share-block {
    height: 75%;
    display: none;
  }

  .vonage-controls {
    button {
      margin-right: 10px;
      position: relative;
      .screen-sharing-message {
        position: absolute;
        top: -5px;
        left: 50px;
        color: black;
        border: 1px solid #d6d6d6;
        padding: 5px 10px;
        background-color: white;
        z-index: 9999999999;
        width: max-content;
        display: none;
      }
      &:disabled {
        background-color: #e6e6e6;
        border-color: white;
        cursor: unset;
        &:hover .screen-sharing-message {
          display: block;
          &:hover {
            display: none;
          }
        }
      }
    }
  }
}

.OT_edge-bar-item {
  .OT_archiving-light-box {
    display: none !important;
  }
  .OT_archiving-status {
    display: none !important;
  }
}

.OT_mini {
  .OT_name.OT_mode-off,
  .OT_name.OT_mode-on,
  .OT_name.OT_mode-auto:hover .OT_name.OT_mode-auto {
    display: block !important;
  }
}

@media only screen and (min-width: 1024px) {
  .OT_widget-container {
    max-width: 1280px;
    max-height: 720px;
  }
}

@media only screen and (min-width: 600px) {
  .opentok-wrapper.screen-sharing > div:not(.control-container) .vonage-meeting {
    .OTPublisherContainer,
    .OT_subscriber {
      max-width: 400px;
    }
  }
}

@media only screen and (max-width: 1245px) {
  .opentok-wrapper:not(.screen-sharing) .vonage-meeting {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }
}

@media only screen and (max-width: 768px) {
  .opentok-wrapper {
    &.screen-sharing {
      .OT_edge-bar-item {
        font-size: 12px !important;
      }
      .OT_edge-bar-item {
        height: 20px !important;
        line-height: 20px !important;
      }
    }
  }
}

@media only screen and (max-width: 867px) {
  .opentok-wrapper:not(.screen-sharing) .vonage-meeting {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    .OTPublisherContainer,
    .OT_subscriber, .OT_publisher {
      min-height: 250px;
    }
  }
  .opentok-wrapper {
    &.screen-sharing {
      .OT_edge-bar-item {
        font-size: 11px !important;
      }
      .OT_edge-bar-item {
        height: 16px !important;
        line-height: 16px !important;
      }
    }
  }
}

.pagination-buttons{
  position: absolute;
  z-index: 2;
}

.next-page-btn{
  right: 0;
}

.pagination-btn{
  padding:0 20px;
  position: absolute;
  top: 45%;
  background: transparent;
  border: none;
  color: $pagination-button-color;
  opacity: 0.8;
  &:hover{
    opacity: 1;
  } 
}