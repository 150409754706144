// Initialization of global variables, mixins and functions
@import '../../react-banzai/styles/_metronic/init';

.profile-page {
  background-color: #fff;
  border-radius: 4px;
  color: #000000;
  font-size: 16px;
  .bio-info{
    word-break: break-word;
  }

  .profile-main-info {
    word-break: break-word;
    h5 {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 6px;
    }
    .stats {
      margin-top: 50px;
      padding-left: 27px;
      @include media-breakpoint-down(sm) {
        padding-left: 0px;
        margin-top: 0;
      }
      @include media-breakpoint-down(xs) {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .user-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 15px;
      text-align: center;
      @include media-breakpoint-down(xs) {
        align-items: center;
      }
      .avatar {
        width: 170px;
        height: 180px;
        display: flex;
        background-color: #ececec;
        border-radius: 21px;
        margin-bottom: 14px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          background: #ececec;
          object-fit: revert;
        }
      }
    }
    .edit-profile-button {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      @include media-breakpoint-down(xs) {
        justify-content: center;
      }
    }
  }
}
