.agenda-card {
	&-head{
		border-bottom: 2px solid #dddddd;
		padding-bottom: 10px !important;
		margin-bottom: 10px;
	}
	.red-dot {
		display: inline-block;
		width: 9px;
		height: 9px;
		background-color: #ff0000;
		border-radius: 50%;
		margin-right: 5px;
	}
	.slick-slider {
		margin-bottom: 0;
		.slick-track{
			display: flex;
			margin-left: 0;
		}
		button.slick-arrow {
			z-index: 111;
			height: 50px;
			&.slick-prev {
				&::before {
					content: "\2039";
				}
			}
			&.slick-next {
				&::before {
					content: "\203A";
				}
			}
			&::before {
				font-family: unset;
				font-size: 50px;
				line-height: 0.5;
				color: #B5B5C3 !important;
			}
		}
	}

	&-desc{
		font-size: 16px;
		width: 75%;
		max-width: 1000px;
		@media only screen and(min-height: 768px){
			width: 100%;
		}
	}

	.agenda-bookmark{
		cursor: pointer;
		color: #cccccc;
		position: absolute;
		top: 20px;
		right: 12px;
		&.active{
			color: #ff0000;
		}
	}
}

.session-record-modal {
	.modal-content {
		height: 80vh;
	}

	.modal-body {
		padding: 0;
	}
}


.agenda-cards-tab-content{
	.agenda-card {
		border: none;
		margin-bottom: 60px;
	}
}