.file-upload {
  background-color: #ffffff;
  margin: 0 auto;
}

// .file-upload-input {
//   position: absolute;
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   height: 100%;
//   outline: none;
//   opacity: 0;
//   cursor: pointer;
// }

.image-upload-wrap {
  margin-top: 10px;
  border: 4px dashed var(--primary);
  position: relative;
}

// .image-dropping {
//   background-color: var(--primary);
//   border: 4px dashed #187de4;
// }

.image-dropping .drag-text h3 {
  color: white;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: var(--primary);
  padding: 30px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}
