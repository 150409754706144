.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: auto;
  flex-wrap: wrap;
  .custom-button {
    background: #ffffff;
    box-shadow: 0px 2.64516px 5.29032px rgba(0, 0, 0, 0.25);
    border-radius: 6.6129px;
    overflow: hidden;
    max-width: inherit;
    border: none;
    height: 50px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(248, 248, 248, 0.2);
      background-color: rgba(248, 248, 248, 0.3);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(173, 173, 173, 0.3);
      background-color: rgba(173, 173, 173, 0.4);
    }

    .custom-button-item {
      background-color: transparent;
      border: none;
      height: inherit;
    }
    & :not(:first-child) {
      .custom-button-item {
        border-left: 1px solid #bebebe;
      }
    }
    & > div {
      height: inherit;
      &:hover {
        background-color: #e6e6e6;
      }
    }
  }
  .join-button {
    min-width: 155px;
  }
}
