// Initialization of global variables, mixins and functions
@import '../../react-banzai/styles/_metronic/init';

.profile-form {
  background-color: #fff;
  border-radius: 4px;
  color: #000000;
  padding: 48px 112px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  @include media-breakpoint-down(sm) {
    padding: 13px;
  }
  .image-upload-box {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-breakpoint-down(lg) {
        width: max-content;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .avatar {
        width: 184px;
        height: 184px;
        display: flex;
        background-color: #ececec;
        border-radius: 21px;
        overflow: hidden;
        outline: none;
        cursor: pointer;
        border: 2px dashed transparent;
        transition: border 0.24s ease-in-out;
        img {
          width: 100%;
          height: 100%;
          background: #ececec;
          object-fit: cover;
        }
        .file-upload-input {
          display: none;
        }
      }
    }
  }
  .fields {
    gap: 0 60px;
    @include media-breakpoint-down(lg) {
      margin-top: 26px;
    }
    .input-field {
      width: calc(50% - 30px);
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
