.tab-mobile-head {
  display: none;
}

.event-sidebar-container {
  height: 100%;
  &-row {
    flex-wrap: nowrap !important;
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .event-sidebar-container {
    &-row {
      flex-wrap: unset;
      flex-direction: column;
      justify-content: space-between;
      &-content {
        overflow: auto;
        max-height: calc(95vh - 200px) !important;
      }
      .communication-modules {
        width: 100%;
        max-width: unset;
        min-height: unset !important;
        flex: unset;
        .nav-tabs {
          justify-content: center;
        }
        > .tab-content {
          position: fixed;
          display: none;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100vh - 90px);
          max-height: calc(100vh - 90px);
          z-index: 999999999;
          .tab-mobile-head {
            display: flex;
            justify-content: space-between;
            padding: 15px 15px 10px;
            box-shadow: 0px 0px 30px 0px #92929214;
          }
          .aside-content {
            height: calc(100% - 60px) !important;
          }
          &.active {
            display: block;
          }
        }
      }
    }
  }
}

.events-page-nav{
  background-color: white;
  padding: 15px 2rem 0;
  .nav-item{
    font-size: 16px;
    border-color: transparent !important;
    &.active{
      background-color: #eef0f8;
      border-color: #eef0f8;
    }
  }
}

@media only screen and (max-width: 338px){
  .events-page-nav {
    padding:  15px 1.25rem 0;
  }
}