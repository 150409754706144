.buttons-group-wrapper {
  z-index: 9
}

.ticker-message-wrapper {
  max-width: 100%;
  flex: 1 1;
  overflow-x: hidden;
}

.footer-logo {
  height: 15px;
}