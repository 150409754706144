.media-ctrl-bar {
  display: flex;
  padding-bottom: 1em;
  position: absolute;
  bottom: 0;
  width: 100%;

  div {
    flex-grow: 1;
    width: 33%;
  }
}

.media-ctrl-bar__more-buttons {
  button {
    margin-left: 1em;
  }
}

.meeting-icons svg {
  fill: transparent;
  width: 30px !important;
  height: 30px !important;
}