.character-avatar{
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    background-color: #07243A;
    display: flex;
    max-width: 100%;
    max-height: 100%;
}

