.header-session-timing{
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 10px;
  .session-control-side{
    &-legend{
      border: 1px solid #ababab;
      padding: 0 26px 5px 10px;
      margin-top: -7px !important;
      border-radius: 5px;
      legend{
        font-size: 12px;
        margin: 0;
        padding: 0 3px;
      }
      span{
        margin-left: 5px;
      }
    }
  }
}