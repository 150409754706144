@media only screen and (max-width: 768px) {
    .full-layout-wrapper {
        max-height: unset !important;
    }
    .wrapper {
        height: 100%;
    }
    .sidebar-layout {
        .with-aside {
            flex-direction: column !important;
            .aside {
                width: 100% !important;
                .aside-primary {
                    width: inherit;
                    .aside-nav {
                        width: inherit !important;
                        ul {
                            width: inherit;
                            display: flex;
                            flex-direction: row !important;
                            justify-content: space-between;
                            padding: 5px 3%;
                            overflow-x: auto;
                            overflow-y: hidden;
                            li {
                                .menuBox {
                                    span {
                                        white-space: nowrap;
                                    }
                                    &::after {
                                        transform: rotate(90deg) translateX(-50%);
                                        bottom: -42px;
                                        top: unset;
                                        left: 50%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.bordered {
    border: 5px solid green;
}

.wrapper {
    max-height: 100vh;
    overflow-y: auto;
}

.full-layout-wrapper {
    max-height: calc(100vh - 110px);
}

.sidebar-layout {
    &-content {
        position: relative;
        .custom-loader {
            position: absolute;
            z-index: 999;
            top: 0;
            left: 0;
            background-color: #eef0f8;
        }
    }
}