$sponsor-level-background: #000a28;

.sponsor-level {
  min-width: 150px;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sponsor-level-background;

  &__name {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.sponsor-card {
  border-radius: 10px;
  position: relative;

  .card-header {
    padding: 20px 0 0;
    height: 140px;

    img {
      max-height: 80px;
    }
  }

  &__border {
    border: 1px solid #c9c9c9;
    box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.25);
  }

  &__body {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      color: #525252;

      p {
        font-size: 16px;
        font-weight: 700;
        padding-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px;
        @media (max-width: 1500px) {
          max-width: 200px;
        }
      }

      div {
        align-self: baseline;

        .badge {
          line-height: 1.5rem;
          background-color: #3699ff;
          color: #ffffff;
          text-transform: uppercase;
        }

        .join-button {
          min-width: max-content;
        }
      }
    }

    .text {
      color: #000000;
      margin-bottom: 4px;
    }

    .link {
      color: #0092cc;
      display: inline-block;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    &-links {
      &-item {
        display: block;
        padding: 3px 0;
        background-color: white !important;
        border: 0;
        text-align: left;
        &:hover {
          span {
            color: #0092cc;
          }
        }

        span {
          line-height: 15px;
          display: block;
          color: #000a28;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
}
