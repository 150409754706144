
.add-to-calendar-show{
  // display: inline-block;
  .chq-atc{
      position: relative;
      display: inline-block;
      >button{
        border: none;
        background-color: white;
        margin-bottom: 2px;
        color: #0077ff;
        &:hover{
          color: #00489b;
        }
        svg{
          display: none;
        }
      }
      .chq-atc--dropdown{
        position: absolute;
        background-color: white;
        display: flex;
        flex-direction: column;
        bottom: 0;
        min-width: 200px;
        left: 50%;
        z-index: 1000;
        padding: 10px 20px;
        box-shadow: 0px 0px 30px 0px #b7b7b75c;
        transform: translate(-50%, 100%);
        a{
          margin: 1px 0;
          &::before{
            content: "";
            display: inline-block;
            margin-right: 7px;
            margin-bottom: -4px;
            background-size: 18px;
            width: 18px;
            height: 18px;
            background-position: center;
            background-repeat: no-repeat;
          }
          &:nth-child(1)::before{
            background-image: url(../../assets/icons/safari.svg);
          }
          &[href*="https://calendar.google.com"]::before{
            background-image: url(../../assets/icons/search.svg);
          }
          &:nth-child(3)::before{
            background-image: url(../../assets/icons/outlook.svg);
          }
          &[href*="https://outlook.live.com"]::before{
            background-image: url(../../assets/icons/outlook-web.svg);
          }
          &[href*="https://calendar.yahoo.com"]::before{
            background-image: url(../../assets/icons/yahoo.svg);
          }
        }
      }
    }
  >span{
    font-weight: 600;
  }
}