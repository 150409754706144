.sponsor-custom-button-modal {
	.modal-dialog {
		word-break: break-word ;
		.modal-body{
			max-height: 80vh;
			overflow: auto;
			iframe{
				max-width: 100%;
				max-height: 70vh;
			}
		}
	}
}
