.event-detail {
  margin-top: 65px;
}

.card {
  &.no-gap {
    margin: 0px;
  }
  &.no-border-top {
    border: 0px;
    border-radius: 0 0 0.42em 0.42em;
  }
  &.no-border-bottom {
    border: 0px;
    border-radius: 0.42em 0.42em 0 0;
  }
}
