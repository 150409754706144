@import '../../react-banzai/styles/_metronic/init';

.profile-tabs {
  background-color: white;
  nav.nav-tabs {
    margin-top: 13px;
    padding-left: 2rem;
    a {
      border-color: transparent !important;
      background-color: white;
      font-size: 16px;
      margin-bottom: 0;
      &.active {
        background-color: #eef0f8 !important;
      }
    }
  }
  .tab-content {
    font-size: 16px;
    background-color: #eef0f8;
    .profile-tab {
      padding: 1.25rem;
    }
    .events-tab {
      padding: 1.25rem !important;
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
  }
}
