@import '../../../styles/_metronic/components/variables.bootstrap';

.menuBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  transition: color 0.3s ease;
  position: relative;
  padding: 3px 6px 0;
  font-size: 12px;
  border-radius: initial;
  border: none;
  color: var(--customColor) !important;

  //session live

  &.is-session-active{
    position: relative;
    &::before{
      content: "";
      position: absolute;
      left: 50%;
      top: 43%;
      transform: translate(-50%, -50%);
      max-width: 40px;
      width: 40px;
      height: 34px;
      z-index: 9999;
      background-image: url(../../../assets/icons/stockholm/live.svg);
      background-repeat: no-repeat;
      background-size: 40px auto;
    }
  }

  &.menuBox:hover {
    color: var(--customColor);
    transition: color 0.3s ease;
    cursor: pointer;
    transition: fill 0.3s ease;

    .stlm-icon-secondary g [class$='stlm-fill'] {
      transition: fill 0.3s ease;
      fill: var(--customColor);
    }
  }

  &.menuBox::after {
    content: "";
    position: absolute;
    right: -3px;
    top: 0;
    height: 100%;
    width: 0;
    background-color: var(--customColor);
  }

  &.menuBoxActive {
    .menuContent {
      color: var(--customColor);
    }
    &::after {
      width: 4px;
    }
  }

  .menuIcon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
    svg{
      width: 25px !important;
      height: 25px !important;
    }
  }
    .menuContent{
      font-size: 14px;
    }

  @media screen and (max-height: 800px){
    .menuIcon {
      width: 35px;
      height: 35px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  @media screen and (max-height: 500px){
    .menuIcon {
      width: 26px;
      height: 26px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
