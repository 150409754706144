// @import 'ki/ki/ki';
@import '_metronic/style.react';
// at this point, metronic has already set the $theme-colors
@import 'banzai/stlmicons';
@import 'banzai/utilities';


::-webkit-scrollbar {
	width: 5px;
	height: 9px;
  }
  ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: rgba(0, 0, 0, 0.4);
  }
