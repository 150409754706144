.leader-item-container {
  .leader-name {
    font-size: 24px;
    font-weight: 500;
  }

  .leader-company {
    font-size: 14px;
    font-weight: 400;
  }

  .leader-place {
    color: #3e97d4;
    font-size: 50px;
  }

  .progress-bar-wrapper {
    width: 400px;
    min-width: 100px;
  }

  .progress {
    height: 20px;
    border-radius: 12px;
  }

  .total-score {
    font-size: 46px;
    font-weight: 300;
    color: #666c7e;
    margin-left: 30px;
  }
}

.rules-container {
  margin: 20px 0;

  .rule-name {
    font-size: 32px;
    font-weight: 300;
  }

  .rule-description {
    font-size: 24px;
    font-weight: 300;
  }

  .rule-point {
    color: #3e97d4;
    font-size: 32px;
  }
}

.my-points-container {
  margin: 20px;

  h1 {
    font-size: 32px;
    font-weight: 300;
  }

  .point-score {
    color: #3e97d4;
    font-size: 40px;
    font-weight: 500;
  }

  .my-point-list-item {
    font-size: 24px;
    font-weight: 300;
  }

  .my-points-score {
    color: #3e97d4;
    font-weight: 500;
    font-size: 24px;
    padding-left: 4px;
  }

  .divider {
    height: 1px;
    background-color: #000;
  }
}

.bg-color-0 {
  background-color: #EE9F15;
}

.bg-color-1 {
  background-color: #1C97D4;
}

.bg-color-2 {
  background-color: #1BCDC3
}

.bg-color-3 {
  background-color: #A0A0A0
}

.bg-color-4 {
  background-color: #71CDFA
}

.bg-color-5 {
  background-color: #676767
}

.bg-color-6 {
  background-color: #28F0FF
}

.bg-color-7 {
  background-color: #66C2E7
}

.bg-color-8 {
  background-color: #81D1F1
}

.bg-color-9 {
  background-color: #FFC061
}

.bg-color-10 {
  background-color: #C9DDFD
}

.bg-color-11 {
  background-color: #D0D0D0
}

.nav.nav-tabs {
  margin-bottom: 0 !important;
}