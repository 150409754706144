@mixin vertical-center {
  display: flex;
  align-items: center;
  background-color: #000000;
}
@mixin jwplayer-styles {
  & > div:first-child {
    height: 100% !important;
    & > div:first-child {
      padding-top: initial !important;
    }
  }
}

.brightcoveVideoJsFix {
  & > :first-child {
    width: 100% !important;
    height: 100% !important;
  }
}

.vimeoVideoIframeFix {
  @include vertical-center;
  iframe:first-child {
    width: 100% !important;
    height: 100% !important;
  }
}

.jwplayer-w100h100 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 100%;
  height: 100%;
}

.jwplayer-w100h75 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 100%;
  height: 75%;
}

.jwplayer-w100h50 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 100%;
  height: 50%;
}

.jwplayer-w100h25 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 100%;
  height: 25%;
}

.jwplayer-w75h100 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 75%;
  height: 100%;
}

.jwplayer-w75h75 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 75%;
  height: 75%;
}

.jwplayer-w75h50 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 75%;
  height: 50%;
}

.jwplayer-w75h25 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 75%;
  height: 25%;
}

.jwplayer-w50h100 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 50%;
  height: 100%;
}

.jwplayer-w50h75 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 50%;
  height: 75%;
}

.jwplayer-w50h50 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 50%;
  height: 50%;
}

.jwplayer-w50h25 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 50%;
  height: 25%;
}

.jwplayer-w25h100 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 25%;
  height: 100%;
}

.jwplayer-w25h75 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 25%;
  height: 75%;
}

.jwplayer-w25h50 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 25%;
  height: 50%;
}

.jwplayer-w25h25 {
  @include vertical-center;
  @include jwplayer-styles;
  width: 25%;
  height: 25%;
}
