
  .ms-video-chat{
  header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  form {
    max-width: 450px;
    margin: 30px auto;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    padding: 20px;
  }
  
  input {
    display: block;
    width: 100%;
    border-radius: 8px;
    border: 2px solid transparent;
    height: 34px;
    padding: 5px;
    // background: #37474f;
    color: inherit;
    font-family: inherit;
  }
  
  input::placeholder {
    color: #aaa;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  .btn-primary {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 6px 14px;
    background-color: #1565c0;
    color: white;
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
  }
  
  form h2,
  .conference-section h2 {
    margin-bottom: 20px;
  }
  
  .btn-danger {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 6px 14px;
    background-color: #f44336;
    color: white;
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
  }
  
  .conference-section {
    padding: 20px 30px;
    max-width: 960px;
    margin: 0 auto;
  }
  
  .conference-section h2 {
    text-align: center;
    font-size: 32px;
    padding-bottom: 10px;
    border-bottom: 1px solid #546e7a;
  }
  
  .peers-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, 1fr));
    place-items: center;
    grid-gap: 10px;
  }
  
  .peer-video {
    height: 250px;
    width: 250px;
    border-radius: 40%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .local.peer-video {
    transform: scaleX(-1);
  }
  
  .peer-name {
    font-size: 14px;
    text-align: center;
  }
  
  .peer-container {
    padding: 10px;
  }
  
  .control-bar {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    justify-content: center;
    z-index: 10;
  }
  
  .control-bar > *:not(:first-child) {
    margin-left: 8px;
  }
  
  .btn-control {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid #37474f;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    text-align: center;
    background-color: #607d8b;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;
  }
  
  .hide {
    display: none;
  }
}