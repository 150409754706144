.custom-menu-popup-type {
  .modal-content {
    height: 90vh;
  }

  .modal-body {
    padding: 0;
  }
}

.aside-left {
  min-width: 80px;
  .aside-nav {
    li.nav-item {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
