@import 'dropzone';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-logo {
  max-width: 90px;
  height: auto;
}

.btn-close {
  font-size: 28px;
  padding: 0 !important;

  &:hover {
    text-decoration: none;
  }
}

.auth-logo {
  margin: 0 auto 20px;
  max-width: 150px;
  display: block;
}

.auth-section {
  width: 100%;
  max-width: 450px;
  margin: 20vh auto 0;
}

.aside-primary {
  min-width: 93px;
}

.form-wrap {
  position: relative;
}

.loading-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 100;
}

.profile-form_avatar-close {
  right: 3px;
  top: 3px;
  height: 20px !important;
  width: 20px !important;
}

.flex-1 {
  flex: 1;
}

.min-h-100 {
  min-height: 100%;
}

.min-w-100 {
  min-width: 100%;
}

.w-0 {
  width: 0;
}

.h-0 {
  height: 0;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.account-card .card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.hide-controls-jw-player {
  .jw-controls {
    height: 0 !important
  }
}

.jw-text-live {
  color: rgba(255, 255, 255, 0.8);

  &::before {
    color: red !important;
  }
}

.object-fit-contain {
  object-fit: contain;
}

body {

  >.OT_subscriber,
  >.OT_publisher {
    display: none;
  }
}

.pointer-events-none {
  pointer-events: none;
}