@each $color, $value in $theme-colors {
  .stlm-icon-#{$color} {
    fill: $value;

    g, path {
      [class$="stlm-fill"] {
        fill: $value;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer !important
}

.cursor-initial {
  cursor: initial !important;
}

//@each $color, $value in $theme-colors {
//.btn-outline-#{$color} {
//@include button-outline-variant($value);
//}
//}
